export default {
  message: {
    matchjian: '赛事简介',
    matchtime: '活动时间:',
    matchadress: '活动地点:',
    baomin: '报名须知:',
    matchbao: '赛事报名',
    mybao: '我要报名',
    ren: '人',
    dui: '队',
    yaoqing: '邀请码',
    yijin: '已报满',
    jion: '加入队伍',
    qinterm: '请输入团队密码查找',
    nowjion: '立即加入',
    matchdet: '赛事详情',
    qinyaoqin: '请输入邀请码',
    confirm: '确 定',
    choose: '项目选择',
    go: '至',
    group:'团队信息',
    tijiao:'提交团队信息',
    baomingroup:'报名组信息',
    adduser:'添加选手',
    yaoqinfr:'邀请好友(团队密码:',
    name:'姓名',
    sex:'性别',
    idtype:'证件类型',
    idnumber:'证件号码',
    action:'操作',
    aread:'已选择选手',
    aread1:'人,男性',
    aread2:'人,女性',
    submit:'提交报名信息',
    mianze:'免责声明',
    noagree:'不同意',
    agree:'同意',
    newlook:'信息查看',
    matchnew:'赛事信息',
    matchname:'活动名称:',
    baomintime:'报名时间:',
    baomintiao:'报名条件:',
    baomingr:'报名组别:',
    geren:'个人中心',
    wode:'我的报名',
    momatch:'比赛证书',
    myinfo:'个人信息',
    myvideo:'我的视频',
    myphone:'手机号',
    mycode:'验证码',
    mycodere:'请输入验证码',
    password:'密码',
    repassword:'重复密码',
    save:'保存',
    zhenshu:'比赛证书',
    look:'查看',
    sacezhen:'请长按证书保存',
    shendown:'证书下载',
    shangwu:'商务合作',
    matchzixun:'赛事咨询',
    meiti:'媒体合作',
    dinyue:'订阅号',
    footer:'版权所有©每步科技（上海）有限公司    沪ICP备15054729号',
    jionpop:'参赛人:',
    status:'报名状态',
    under:'审核中',
    more:'更多信息',
    pay:'支付信息',
    payamout:'支付金额:',
    paysty:'支付方式:',
    paycode:'支付单号:',
    paytime:'支付时间:',
    nopay:'未支付',
    codesta:'订单状态',
    codeguo:'审核中,请耐心等待',
    sure:'确认报名',
    contact:'联系我们',
    about:'关于每步',
    search:'搜索比赛',
    login:'登录',
    out:'注销',
    dearh:'首页',
    ziyun:'志愿者',
    pol:'赛事照片',
    nowlogin:'立即登录',
    menu:'菜单',
    timeout:'信息截止修改倒计时:',
    day:'天',
    hour:'时',
    min:'分',
    sec:'秒',
    term:'团队名称:',
    addapp:'添加选手',
    addnew:'页面加载中...如果长时间未加载成功。请确认报名是否结束',
    dier:'重新载入'
  }
}
