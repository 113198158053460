export default {
  message: {
    matchjian: 'Event introduction',
    matchtime: 'Competition Schedule:',
    matchadress: 'Competition Venue:',
    baomin: 'Registration notice:',
    matchbao: 'Registration of the event',
    mybao: 'Join the race',
    ren: 'person',
    dui: 'team',
    yaoqing: 'Invitation code',
    yijin: 'Reported full',
    jion: 'Join the team',
    qinterm: 'Please Enter Team Code',
    nowjion: 'Join now',
    matchdet: 'Competition Details',
    qinyaoqin: 'Please input the invitation code',
    confirm: 'Confirm',
    choose: 'project choose',
    go: 'to',
    group:'team information',
    tijiao:'submit team information',
    baomingroup:'Registration group information',
    adduser:'add player',
    yaoqinfr:'invite friends(team code:',
    name:'Name',
    sex:'Gender',
    idtype:'ID type',
    idnumber:'ID number',
    action:'operating',
    aread:'Selected player',
    aread1:'person,male',
    aread2:'person,female',
    submit:'Submit registration information',
    mianze:'Disclaimer',
    noagree:'disagree',
    agree:'agree',
    newlook:'Information view',
    matchnew:'Event information',
    matchname:'competition:',
    baomintime:'Registration Time:',
    baomintiao:'Registration conditions:',
    baomingr:'Registration group:',
    geren:'Personal center',
    wode:'My registration',
    momatch:'Competition certificate',
    myinfo:'Personal information',
    myvideo:'My video',
    myphone:'phone number',
    mycode:'Verification code',
    mycodere:'please enter verification code',
    password:'password',
    repassword:'Repeat the password',
    save:'save',
    zhenshu:'Competition certificate',
    look:'view',
    sacezhen:'Please press and hold the certificate to save',
    shendown:'Certificate download',
    shangwu:'Business Cooperation',
    matchzixun:'Tournament consultation',
    meiti:'media cooperation',
    dinyue:'Subscription number',
    footer:'Copyright ©Move Technology(shanghai)Co.,Ltd.    Shanghai ICP No. 15054729',
    jionpop:'Participants:',
    status:'Registration status',
    under:'Under review',
    more:'More information',
    pay:'Payment information',
    payamout:'Amount paid:',
    paysty:'Payment method:',
    paycode:'Payment order number:',
    paytime:'Time to pay:',
    nopay:'Unpaid',
    codesta:'Order status',
    codeguo:'During the review, please be patient',
    sure:'Confirm registration',
    contact:'Contact us',
    about:'About Move',
    search:'Search match',
    login:'Login',
    out:'Logout',
    dearh:'Home',
    ziyun:'Volunteers',
    pol:'Match Photos',
    nowlogin:'Sign in now',
    menu:'Menu',
    timeout:'Information cut-off revision countdown:',
    day:'days',
    hour:'hours',
    min:'minutes',
    sec:'seconds',
    term:'Team name:',
    addapp:'Add player',
    addnew:'Page load... If the load is not successful for a long time. Please confirm that the registration is closed',
    dier:'Reloading'
  }
}
